import { render, staticRenderFns } from "./comentarios.vue?vue&type=template&id=185a99aa&scoped=true"
import script from "./comentarios.vue?vue&type=script&lang=js"
export * from "./comentarios.vue?vue&type=script&lang=js"
import style0 from "./comentarios.vue?vue&type=style&index=0&id=185a99aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185a99aa",
  null
  
)

export default component.exports
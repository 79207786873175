export default [
    {
        path: '/admin/pasarela-pagos',
        meta: { nombre: 'Pasarela de pagos' },
        name: 'admin.pasarela-pagos',
        component: () => import(/* webpackChunkName: "pasarela-pagos" */ '../../pages/pasarela').then(m => m.default || m),
        redirect: { name: 'admin.pasarela-pagos.metodos-pago' },
        children: [
            {
                path: '',
                meta: { nombre: 'Pasarela de pagos - Metodos de pago' },
                name: 'admin.pasarela-pagos.metodos-pago',
                component: () => import(/* webpackChunkName: "pasarela-pagos.metodos-pagos" */ '../../pages/pasarela/metodosPago').then(m => m.default || m),
                redirect: { name: 'admin.pasarela-pagos.metodos-pago.mercadopago' },
                children: [
                    {
                        path: 'mercadopago',
                        meta: { nombre: 'Pasarela de pagos' },
                        name: 'admin.pasarela-pagos.metodos-pago.mercadopago',
                        component: () => import(/* webpackChunkName: "pasarela-pagos.metodos-pagos.mercadopago" */ '../../pages/pasarela/mercadopago').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'mercadopago/cuentas',
                meta: { nombre: 'Pasarela de pagos' },
                name: 'admin.pasarela-pagos.metodos-pago.mercadopago.cuentas',
                component: () => import(/* webpackChunkName: "pasarela-pagos.metodos-pagos.mercadopago" */ '../../pages/pasarela/mercadopago/cuentas').then(m => m.default || m),
            }
        ],
    }
]

<template>
    <div :ref="`vista-${user}`" class="chat-general position-relative" :class="fondo?'bg-chat':''">
        <button v-show="mensajes.length > 0" class="scrollToTopBtn2 border-gris position-absolute" @click="restablecerScroll">
            <i class="icon-chevron-double-down f-25 text-general" />
        </button>
        <!-- <div id="flipbookContainer" class="_df_custom" webgl="false" :source="source" download="false" /> -->
        <audio id="audio" src="/audio/iphone-notificacion.mp3" preload="auto" />
        <div id="scrollGeneral" class="overflow-auto px-3 custom-scroll" :style="finalizado?'height:100%;':`height:calc(100% - ${notific?'58px': (height? '147px' :'119px')});`" @scroll.passive="handleScroll">
            <!-- <div id="scrollGeneral" class="overflow-auto pr-3 custom-scroll" style="height:100%;"> -->

            <infinite-loading ref="infiniteLoading" direction="top" @infinite="listarChats">
                <div slot="spinner">
                    <div>
                        Cargando...
                    </div>
                </div>
                <div slot="no-more" class="text-general2">
                    <span v-if="false">No hay más mensajes</span>
                </div>
                <div slot="no-results" class="text-general2">
                    <span v-if="mensajes.length == 0">
                        No se encuentran mensajes
                    </span>
                </div>
            </infinite-loading>

            <div v-for="(info, idx) in mensajes" :key="idx" class="row mx-0 px-2 my-3 no-gutters">
                <div v-if="mostrarFecha(idx)" class="col-12 d-middle pb-3">
                    <div class="col border-bottom" />
                    <div class="px-2 text-general2">
                        {{ formatearFecha(info.created_at, 'DD MMMM Y') }}
                    </div>
                    <div class="col border-bottom" />
                </div>
                <img
                v-if="false"
                class="rounded-circle obj-cover"
                height="32"
                width="32"
                :src="_.defaultTo(null, '/img/no-imagen/sin_user.png')"
                />
                <!-- cuando es soporte desde Admin -->
                <div v-if="parseInt(info.tipo)===4" class="col-12 d-flex mx-0 px-2 my-3 no-gutters">
                    <div class="col-12 d-middle-center px-3 bg-light-f5 text-general2 br-12 text-center cr-pointer" @click="modalInfoSoporte(info)">
                        Soporte # {{ info.num }}: Finalizado por {{ info.name }} . {{ info.fecha_fin }}
                    </div>
                </div>
                <!--  ---------------------solo para chat de cŕeditos ------------------- -->
                <div v-if="parseInt(info.tipo)===31 || parseInt(info.tipo)===32 || parseInt(info.tipo)===33" class="bg-white col-12 br-5 p-2 px-3 f-15">
                    <div class="row mx-0 justify-center my-1 f-17 f-500">
                        <span v-if="parseInt(info.tipo)===31">Nuevo Crédito</span>
                        <span v-else-if="parseInt(info.tipo)===32">Pago realizado</span>
                        <span v-else>Crédito Anulado</span>
                        <i v-if="parseInt(info.tipo)===31" class="icon-receipt text-primary" />
                        <i v-else-if="parseInt(info.tipo)===32" class="icon-ok-circled-outline text-success" />
                        <i v-else class="icon-cancel-circled-outline text-danger" />
                    </div>
                    <p style="white-space:break-spaces;"> {{ info.mensaje }}</p>
                </div>
                <!--  ---------------------FIN solo para chat de cŕeditos ------------------- -->
                <div v-show="info.tipo!== 4 && !(parseInt(info.tipo)===31 || parseInt(info.tipo)===32 || parseInt(info.tipo)===33)" class="col-9 ml-3" :class="!info.mio ? 'mr-auto' : 'ml-auto'">
                    <div class="row mx-0">
                        <div class="col-auto px-0 br-5 chat-bubble" :class="!info.mio ? 'chat-bubble-left' : 'chat-bubble-right ml-auto'">
                            <div v-show="parseInt(info.tipo)===2" class="row mx-0">
                                <div class="col p-2 cr-pointer">
                                    <img class="w-100 cr-pointer" :src="info.mensaje_firmado" style="max-width:200px;max-height:200px;" @click="verImagen(info.mensaje_firmado)" />
                                </div>
                            </div>
                            <div v-show="parseInt(info.tipo)===3" class="col-auto d-middle card-archivo bg-whitesmoke border br-4 m-2 px-2 cr-pointer" @click="openAdjunto(info.mensaje_firmado)">
                                <i class="icon-file-pdf-box f-18 mr-2 text-danger" />
                                <p class="f-14 tres-puntos text-dark"> {{ nombrePdf(info.mensaje) }} </p>
                            </div>
                            <div v-show="info.mensaje" class="row mx-0 my-1 f-13">
                                <div class="col">
                                    <div v-if="tab == 2 && $usuario.rol_nombre == 'tendero' && height" class="row mx-0 justify-content-end">
                                        <i class="text-general2" v-text=" info.mio==0 ? cliente.nombre : 'Cedis' " />
                                    </div>
                                    <div v-else-if="$usuario.rol_nombre == 'admin'" class="row mx-0 justify-content-end">
                                        <i class="text-general2" v-text="info.nombre_usuario" />
                                    </div>
                                    <div class="row mx-0 justify-content-end">
                                        <p v-show="parseInt(info.tipo)===1" class="f-15 text-general" style="word-break: break-all;white-space: break-spaces;">
                                            {{ info.mensaje }}
                                        </p>
                                    </div>
                                    <div class="row mx-0 justify-content-end">
                                        <i class="text-general2" v-text="formatearFecha(info.created_at,'HH:mm a')" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="info.mio==0" class="chat-bubble-arrow-left" />
                            <div v-else class="chat-bubble-arrow-right" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="finalizado" class="row mx-2 bg-whitesmoke p-2 br-10">
                <span class="pr-2 f-600">Finalizado</span> {{ fechaFinalizado=== null? 'No registra' : formatearFecha(fechaFinalizado,'D MMM Y HH:mmA') }}
            </div>
            <div v-if="false" class="row h-100 mx-0 align-items-center justify-content-center">
                <div class="text-center f-15 d-middle-center flex-column" style="width:225px;">
                    <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                        <img height="100" src="/img/no-imagen/question.svg" alt="" />
                    </div>
                    <p>Aún no te has comunicado con "Leeche"</p>
                </div>
            </div>
        </div>
        <template v-if="tab == 2 && $usuario.rol_nombre == 'tendero' && height">
            <div class="bg-general3 text-white br-10 f-18 text-center py-3 my-3">
                Chat entre Cedis y {{ cliente.nombre }}
            </div>
        </template>
        <template v-else>
            <div v-show="!finalizado" class="w-100 p-2 position-relative" style="bottom:0;height:88px;" :class="fondo?'bg-chat':''">
                <ValidationObserver ref="validacion" v-slot="{ valid }" tag="div" class="w-100 d-flex align-items-center mx-0 position-absolute px-3" style="bottom:5px;left:0px">
                    <div v-loading="loading" :element-loading-text="`${cargando}`" class="col px-0">
                        <ValidationProvider rules="required|max:1000" tag="div" name="mensaje" class="position-relative bg-white br-20 d-middle container-button">
                            <el-input
                            v-model="model.mensaje"
                            type="textarea"
                            :autosize="{minRows: 0, maxRows: 3}"
                            class="input-chat"
                            placeholder="Mensaje"
                            @keydown.native="detectarEnter($event)"
                            />
                            <el-upload
                            ref="adjuntos"
                            class="upload-demo"
                            action="#"
                            :limit="1"
                            :on-change="agregar_archivos"
                            :auto-upload="false"
                            accept="image/*, application/pdf"
                            >
                                <!-- <i v-if="cont < 3 || limitarMensajes" slot="suffix" class="el-input__icon icon-attach text-general2 cr-pointer" /> -->
                                <i v-if="cont < 3 || limitarMensajes" class="icon-attach text-general2 cr-pointer" />
                            </el-upload>
                        </ValidationProvider>
                    </div>
                    <template v-if="!limitarMensajes">
                        <i v-if="valid && cont <3" class="icon-send text-general-red cr-pointer f-20" @click="enviarMensaje" />
                    </template>
                    <template v-else>
                        <i v-if="valid && !loading" class="icon-send text-general-red cr-pointer f-20" @click="enviarMensaje" />
                    </template>
                </ValidationObserver>
                <div v-if="!limitarMensajes">
                    <div class="row mx-0 px-2 mt-2">
                        <div class="col br-20 bg-grr-red text-general text-center f-15">
                            Tiene {{ 3-cont }} mensajes permitidos
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <modalImagen ref="modalVerImagen" />
        <visor-pdf ref="vPdf" />
        <chatModalInfoSoporteFinalizado ref="modalInfoSoporte" />
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
import Chat from '~/services/chat/chat'
import Aws from '~/services/aws'
Vue.use(Viewer)
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import {mapGetters} from 'vuex'

// Vue.use(InfiniteLoading, {
//     slots: {
//         noMore: 'No hay más mensajes', // you can pass a string value
//         noResults: 'No se encuentran mensajes', // you can pass a string value
//         // error: InfiniteError, // you also can pass a Vue component as a slot
//     },
// });
/*
Tipo = 1  tabla users_chat
Tipo = 2  tabla pedidos_chats
Tipo = 3  tabla tiendas_chats
Tipo = 4  tabla users_chats  // Admin muestra con los soportes
Tipo = 5  tabla admin_pedidos_chats  // pedidos módulo almacen(abastecimiento)
Tipo = 6  tabla creditos_chats  // pedidos módulo almacen(abastecimiento) Tab créditos

momento = 2  chat de tipo reclamo
momneto= 1 Logueado como Admmin Admin-vip Tab Reclamos terminados
finalizado = true NO permite escribir nuevos mensaje
limitarMensajes = false limita los mensajes


*/
// let siteTitle = '';
// window.addEventListener('blur', () => {
//     siteTitle = document.title;
//     document.title = 'Come back! :c';
//     console.log('document.title', document.title);
// });
// window.addEventListener('focus', () => {
//     document.title = siteTitle;
//     console.log('document.title focus', document.title);
// });
export default {
    components:{
        InfiniteLoading,
        visorPdf: ()=>import('./visorPdf')
    },
    // sockets:{
    //     mensajes(value){
    //         const obj = JSON.parse(value)
    //         console.log('general', obj.tipo);
    //         // this.listarChats()

    //     }
    // },
    props: {
        fondo: {
            type: Boolean,
            default: false
        },
        finalizado: {
            type: Boolean,
            default: false
        },
        cliente: {
            type: Object,
            default(){
                return {}
            }
        },
        height: {
            type: Boolean,
            default: false
        },
        tab: {
            type: Number,
            default: 0
        },
        fechaFinalizado: {
            type: String,
            default: ''
        },
        notific: {
            type: Boolean,
            default: false
        },
        user :{
            type: Number,
            default: 0
        },
        tipo: {
            type: Number,
            default: 1
        },
        momento: {
            type: Number,
            default: 2 // 2= Reclamo
        },
        limitarMensajes: {
            type: Boolean,
            default: true
        },
        conSoporte: {
            type: Boolean,
            default: false
        },
        desfase: {
            type: Number,
            default: 60 // 60 estaba para chat login
        },
    },
    data(){
        return {
            tipoNoti: '',
            model: {
                mensaje: '',
                tipo: 1,
                id_user: 0,
                created_by: 0,
                momento: 2,
                id_admin_pedido: 0,
                file: null
            },
            mensajes: [],
            archivo: [],
            miRuta: 'chat',
            loading: false,
            cargando: '',
            cont: 0,
            page: 1,
            maxMilisegundos: 600,
            channels: []
        }
    },
    computed:{
        ...mapGetters({
            error: `auth/obtenerError`,
        })
    },
    watch:{
        user(val){
            this.resetear()
        },
        limitarMensajes(){
            //console.log('aqui');
            this.puedoEscribir()
        }
    },
    mounted(){
        this.resetear()
    },
    beforeDestroy(){
        // console.log('beforeDestroy Chat');
    },
    methods:{
        resetear(){
            this.model= {
                mensaje: '',
                tipo: 1,
                id_user: 0,
                created_by: 0,
                momento: this.momento,
                id_admin_pedido: 0
            }
            this.mensajes= []
            if(this.$refs.infiniteLoading){
                this.$refs.infiniteLoading.stateChanger.reset()
            }
            this.page= 1,
            this.unsubscribe_channels()
            this.inicioChat()
            this.delay(this.ocultarIcono())
            if(this.$refs.validacion){
                this.$refs.validacion.reset()
            }


        },
        async puedoEscribir(){
            try {
                const userLogueado = this.$usuario.id !== undefined ? this.$usuario.id : this.error.usuario.id
                var ultimos = []
                if(this.mensajes.length > 2){
                    ultimos = this.mensajes.slice(this.mensajes.length -3, this.mensajes.length);
                }else{
                    ultimos = this.mensajes
                }
                ultimos = _.reverse(ultimos, ['created_at'])
                var cuantosTiene = 0
                var puedeConCero = false
                ultimos.map((e, idx) =>{
                    if(e.created_by !== userLogueado && idx===0)
                    {
                        puedeConCero = true
                    }
                    if(e.created_by === userLogueado && idx===0)
                    {
                        cuantosTiene+= 1
                    }
                    if(e.created_by === userLogueado && idx===1)
                    {
                        cuantosTiene+= 1
                    }
                    if(e.created_by === userLogueado && idx===2)
                    {
                        cuantosTiene+= 1
                    }

                })
                this.cont = puedeConCero ? 0 : cuantosTiene
            } catch (e){
                this.error_catch(e)
            }

        },
        async inicioChat(){
            let nameCanal = 'chats'
            if(this.tipo===1){
                nameCanal = 'chats'
            }else if(this.tipo===2){
                nameCanal = 'chats-pedidos'
            }else if(this.tipo===3){
                nameCanal = 'chats-tiendas'
            }else if(this.tipo===4){
                nameCanal = 'chats' // igual que el 1
            }else if(this.tipo===5){
                nameCanal = 'admin-pedidos-chats'
            }else if(this.tipo===6){
                nameCanal = 'creditos-chats'
            }else if(this.tipo===7){
                nameCanal = 'novedades-chats'
            }

            if (this.sockets=== undefined){
                //this.notificacion('Mensaje','EL socket no esta importado','warning')
            }
            this.sockets.subscribe(`${nameCanal}-${this.user}`, async(value)=>{
                if(this.finalizado){
                    return false
                }
                const {chat} = JSON.parse(value)
                const userLogueado = this.$usuario.id !== undefined ? this.$usuario.id : this.error.usuario.id
                console.log('userLogueado', userLogueado, 'chat.created_by', chat.created_by);
                if(parseInt(userLogueado) !== parseInt(chat.created_by)){ // Tipo = 1 mio 0 = responde
                    // this.notificacion('','Tiene un nuevo mensaje', 'success')
                    const visible = this.$refs[`vista-${this.user}`] // document.getElementById('vista')
                    console.log('visible', visible,'hidden');
                    if(visible !== undefined){
                        console.log('visible.hidden', visible.hidden);
                        // console.log('visible HIDDEN lo tengo !', visible.hidden);
                        if(!visible.hidden){ // detecta si la pestaña esta activa true si el elemento está oculto;
                            this.$emit('actualizar-lectura')
                            const audio = document.getElementById('audio').play()
                        }
                    }
                    this.cont = 0
                    chat.mio = false
                }else{
                    chat.mio = true
                }
                this.mensajes.push(chat)
                await this.deslizar(chat)
                if(this.$refs.infiniteLoading){
                    this.$refs.infiniteLoading.stateChanger.reset()
                }
                this.restablecerScroll()
            })

            this.guardarCanalesSubscritos(`${nameCanal}-${this.user}`)

        },
        unsubscribe(){
            this.unsubscribe_channels()
            this.mensajes= []
            if(this.$refs.infiniteLoading){
                this.$refs.infiniteLoading.stateChanger.reset()
            }

        },
        unsubscribe_channels(){
            this.channels.forEach(element => {
                this.sockets.unsubscribe(element);
            });
            this.channels = []

        },
        guardarCanalesSubscritos(nameCanal){
            if(!this.channels.some(e => e===nameCanal)){
                this.channels.push(nameCanal)
            }
        },
        verImagen(img){
            this.$refs.modalVerImagen.toggle(img)
        },
        async deslizar(chat){
            if (this.necesitaDeslizar()){
                let ms = chat.tipo===1 ? 0 : this.maxMilisegundos
                await this.delay_scroll(ms)
                this.restablecerScroll()
                // console.log('hace');
            } else {
                // console.log('No lo hace');
            }
        },
        necesitaDeslizar(){
            const rootElement = document.getElementById('scrollGeneral')
            //console.log(rootElement);
            if(rootElement === undefined || rootElement === null){
                return false
            }
            var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
            return (rootElement.scrollTop / scrollTotal ) > 0.80

        },
        restablecerScroll(){
            var  element = document.getElementById('scrollGeneral')

            if(element){
                if(element.scrollTo){
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth"
                    })
                }
            }

            //console.log('asq');
        },
        async listarChats($state= false){
            try {
                const state = $state
                var params = {
                    page: this.page,
                    id_user: this.user,
                    momento: this.momento, // solo para chat de pedidos
                    conSoporte: this.conSoporte, // sola para users_chats tipo admin
                    tipo: this.tipo,
                    cedisCliente: this.tab == 2 && this.$usuario.rol_nombre == 'tendero' && this.height

                }
                if(this.tipo===1){ // user solo desde  login
                    var {data} = await Chat.listarChats(params)
                }else if(this.tipo===2){ // tipo pedido
                    var {data} = await Chat.listarChatsPedidos(params)
                }else if(this.tipo===3){ // tiendas
                    params.created_by = this.$usuario.id !== undefined ? this.$usuario.id : this.error.usuario.id
                    var {data} = await Chat.listarChatsTiendas(params)
                }
                else if(this.tipo===4){ // user pero desde el admin
                    var {data} = await Chat.listarChats(params)
                }else if(this.tipo===5){
                    console.log('por aca entro a buscar los chats');
                    const myParams = {
                        page: this.page,
                        momento: this.momento, // solo para chat de pedidos
                        id_admin_pedido: this.user,
                        created_by : this.$usuario.id !== undefined ? this.$usuario.id : this.error.usuario.id
                    }
                    var {data} = await Chat.listarChatsAdminPedidos(myParams)
                }else if(this.tipo===6){
                    const myParams = {
                        page: this.page,
                        id_user: this.user,
                        created_by : this.$usuario.id
                    }
                    var {data} = await Chat.listarChatsCreditosPedidosAdmin(myParams)
                }else if(this.tipo===7){
                    const myParams = {
                        page: this.page,
                        momento: this.momento, // solo para chat de pedidos
                        id_admin_pedido: this.user,
                        created_by : this.$usuario.id !== undefined ? this.$usuario.id : this.error.usuario.id
                    }
                    var {data} = await Chat.listarNovedadesPedidosAdmin(myParams)
                }
                const milisegundos = this.page===1 ? this.maxMilisegundos : 100
                // console.log('----listarChats ---milisegundos', milisegundos);
                if (data.chats.data.length > 0){
                    if(this.page==1){
                        this.mensajes = []
                    }
                    this.page += 1;
                    let ordenados = _.sortBy(data.chats.data, ['created_at'])
                    this.mensajes = ordenados.concat(this.mensajes)
                    await this.delay_scroll(milisegundos)
                    if(state){
                        state.loaded()
                    }

                } else {
                    if(state){
                        state.complete()
                    }
                }
                if(! this.limitarMensajes){
                    this.puedoEscribir()
                }
                this.restablecerScroll()

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        verificarUltimosMensajes(){
            if(this.mensajes.length > 3){

            }
        },
        delay_scroll(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async enviarMensaje(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(this.model.tipo== 1 && !valid){
                    this.notificacion('Mensaje', 'texto obligatorio', 'warning')
                    return false
                }
                if(!this.limitarMensajes && this.cont > 2){// si es falso limita
                    console.log(this.tipo);
                    this.notificacion('Mensaje', 'Limite mensajes excedido', 'warning')
                    return false
                }

                this.model.id_user = this.user
                this.model.created_by = this.$usuario.id === undefined ? this.user : this.$usuario.id
                if(this.model.tipo===2 || this.model.tipo==3){ // imagen o pdf
                    var newModel = this.crear_formData(this.model)
                }else{
                    var newModel = this.model
                }
                this.loading= true
                console.log('this.tipo', this.tipo);
                if(this.tipo===1){
                    console.log('1111');
                    var {data} = await Chat.enviarChat(newModel)
                }else if(this.tipo===2){ // tipo pedido, logueado como tendero responde desde menu Reclamos Tab Reclamos
                    newModel.id_pedido = this.user
                    newModel.momento = this.momento
                    console.log('2222', newModel);
                    var {data} = await Chat.enviarChatPedido(newModel)
                }else if(this.tipo===3){// tipo tienda (Admin responde en menu soporte  tab leecheros ) o el tendero desde menu ayuda-Quiero contactar
                    console.log('333');
                    newModel.created_by = this.$usuario.id !== undefined ? this.$usuario.id : this.error.usuario.id
                    var {data} = await Chat.enviarChatTienda(newModel)
                }else if(this.tipo===4){ // tipo user Admin(desde menu soporte  tab soporte general)
                    console.log('4444');
                    var {data} = await Chat.enviarChat(newModel)
                }else if(this.tipo===5){ //  pedido Admin
                    console.log('5555');
                    newModel.id_admin_pedido = this.user
                    // const payload = {
                    newModel.tipo= this.model.tipo,
                    newModel.id_admin_pedido= this.model.id_admin_pedido,
                    newModel.mensaje= this.model.mensaje,
                    newModel.momento = this.momento
                    newModel.created_by= this.model.created_by

                    // }
                    var {data} = await Chat.enviarChatPedidoAdmin(newModel)
                }else if(this.tipo===6){ //  CHATS CREDITOS  logueado como tendero y menu almacen cŕeditos
                    console.log('66', newModel);
                    newModel.id_admin_pedido = this.user
                    newModel.tipo = this.model.tipo
                    newModel.id_user = this.model.id_user
                    newModel.mensaje = this.model.mensaje

                    var {data} = await Chat.enviarChatCreditosPedidoAdmin(newModel)
                }else if(this.tipo===7){ //  CHATS CREDITOS  logueado como tendero y menu almacen cŕeditos
                    console.log('77', newModel);
                    newModel.id_admin_pedido = this.user
                    // const payload = {
                    newModel.tipo= this.model.tipo,
                    newModel.id_admin_pedido= this.model.id_admin_pedido,
                    newModel.mensaje= this.model.mensaje,
                    newModel.created_by= this.model.created_by

                    var {data} = await Chat.enviarNovedadesPedidoAdmin(newModel)
                }

                this.cont += 1
                //this.listarChats()
                this.restablecerScroll()
                this.limpiarChat()
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async agregar_archivos(file, fileList){
            let nombreMayor100Carecteres = file.name.length > 100
            const TIPO_CHAT = this.tipo
            let tipo = null
            let tamano = null
            if (file.raw.type === 'application/pdf'){
                if(TIPO_CHAT===6){ // cuando es chat de cŕedito no permite subir documentos
                    fileList.splice(fileList.indexOf(file), 1)
                    await this.notificacion('No válido', 'Este chat no permite carga de documentos', 'warning')
                    return
                }
                tipo = 3
                tamano = 20
            } else if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tipo = 2
                tamano = 1000
            } else {
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(pdf ó imágenes)', 'warning')
                return
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', ` El archivo supera los ${tamano} Mb`, 'warning')
                return
            }

            if (nombreMayor100Carecteres){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
                return
            }
            this.model.tipo = tipo
            this.archivo = file
            this.model.file = file.raw
            fileList.splice(fileList.indexOf(file), 1)
            // this.guardarArchivo()
            // this.model.mensaje= 'archivo';
            this.enviarMensaje()


        },
        /** Usaba para guardar por el front */
        async guardarArchivo(){
            try {
                let ruta = `${this.miRuta}/${Aws.getHash()}.${this.archivo.name}`
                // let ruta = `${this.miRuta}/${Aws.getHash()}.${this.archivo.name.split('.').pop()}`
                this.loading = true
                let resp = await Aws.getInstance().upload({
                    Key: ruta,
                    ContentType: this.archivo.type,
                    Body: this.archivo.raw
                }).on('httpUploadProgress',uploadEvent=>{
                    let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                    this.cargando = porcentaje
                }).promise()
                this.loading = false
                this.model.mensaje = resp.Key
                this.enviarMensaje()

            } catch (e){
                this.loading = false
                this.model.tipo = 1
                this.error_catch(e)
            }
        },
        mostrarFecha(idx){
            try {
                if(idx > 0){
                    let resp = moment(this.mensajes[idx].created_at).isSame(this.mensajes[idx -1].created_at, 'day');
                    return !resp
                }else{
                    return true
                }
            } catch (e){
                this.error_catch(e)
            }

        },
        openAdjunto(pdf){
            this.$refs.vPdf.set_pdf(pdf)
        },
        ocultarIcono(){
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn2")
            scrollToTopBtn.classList.remove("showBtn")

        },
        handleScroll(){
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn2")
            // console.log('handleScroll', this.necesitaDeslizar() );
            if (this.necesitaDeslizar()){
                scrollToTopBtn.classList.remove("showBtn")
                // Hide button
            } else {
                scrollToTopBtn.classList.add("showBtn")
                // Show button
            }
            // console.log('Top:', rootElement.scrollTop, ' scrollHeight ', rootElement.scrollHeight, ' Dif: ', rootElement.scrollHeight-rootElement.scrollTop );
            // Obtiene el número de pixels desplazados
            // var  intElemScrollTop = this.$refs.infiniteLoading.scrollParent.scrollTop;
            // console.log('handleScroll', this.$refs.infiniteLoading.scrollParent.scrollHeight, 'top', intElemScrollTop );
            // this.$refs.infiniteLoading.scrollParent.scrollTop = 0

        },
        modalInfoSoporte(info){
            this.$refs.modalInfoSoporte.toggle(info)
        },
        nombrePdf(name){
            if(name){
                let array =  name.split('.')
                return array[array.length - 2]
            }
        },
        eventBlur(){
            console.log('eventBlur');
        },
        detectarEnter(key){
            let code = key.keyCode
            if(code === 13){
                // console.log('detectarEnter', code);
                this.enviarMensaje()
            }
        },
        limpiarChat(){
            this.model.tipo = 1
            this.model.mensaje = null
            this.$refs.validacion.reset()
            this.$refs.adjuntos = []
            this.loading = false
        }
    }
}
</script>

<style lang="css" scoped>
.chat-general{
    /* width:28vw; */
    height:100%;
    /* padding-bottom: 90px; */
}
.bg-chat{
    background: #F0F0F0;
}
.chat-bubble{
    background: #F8FCFF;
    /* border: 1px solid var(--color-general); */
    border: 1px solid #F8FCFF;
}
.chat-bubble-left{
    background: #F5F5F5;
    /* border: 1px solid var(--color-general); */
    /* border: 2px solid #0892DD; */
}
.chat-bubble-right{
    background: #F5F5F5;
    /* border: 1px solid red; */
    color: var(--text-general)!important;
}
.chat-bubble-arrow-left {
    border-color: #F5F5F5 #00000000  #00000000 transparent;
    border-style: solid;
    border-width: 10px;
    top: 0px;
    height: 0;
    right: -10px;
    position: absolute;
    width: 0;
}
.chat-bubble-arrow-right {
    border-color: #F5F5F5 #00000000  #00000000 transparent;
    border-style: solid;
    border-width: 14px;
    top: 0px;
    height: 0;
    right: -10px;
    position: absolute;
    width: 0;
}
.img-chat{
    object-fit: cover;
    width:200px;
    max-width:100%;
    max-height: 200px;
    /* max-height:100%; */
}
.card-archivo{
        max-width: 231px;
}
.icono-subir{
    position: absolute;
    top: -7px;
    right: 5px;
}
 /* bonton se subir */
 .scrollToTopBtn2 {
    background-color: #ffffff;
    opacity: 0.8;
    border: none;
    border-radius: 50%;
    position: fixed;
    right: 23px;
    top: 5px;
    cursor: pointer;
    font-size: 16px;
    /* line-height: 48px; */
    width: 48px;
    height: 48px;

    /* keep it at the top of everything else */
    z-index: 100;
    /* hide with opacity */
    opacity: 0;
    /* also add a translate effect */
    transform: translateY(100px);
    /* and a transition */
    transition: all .5s ease;
    border: 1px solid #DBDBDB;
 }
.showBtn {
  opacity: 1;
  transform: translateY(0)
}
.container-button{
    border:1px solid  #C0C4CC;
}
</style>

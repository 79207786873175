export default [
    {
        path: '/login/vendedor/terminos-condiciones',
        /* meta: { nombre: 'Metricas' }, */
        name: 'login.leechero.terminos-condiciones',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informativaLogin/terminos-condiciones').then(m => m.default || m),
    },
    {
        path: '/login/cliente/terminos-condiciones',
        /* meta: { nombre: 'Metricas' }, */
        name: 'login.cliente.terminos-condiciones',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informativaLogin/terminos-condiciones').then(m => m.default || m),
    },

    {
        path: '/login/vendedor/politica-privacidad',
        /* meta: { nombre: 'Metricas' }, */
        name: 'login.leechero.politica-privacidad',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informativaLogin/politica-privacidad').then(m => m.default || m),
    },
    {
        path: '/login/cliente/politica-privacidad',
        /* meta: { nombre: 'Metricas' }, */
        name: 'login.cliente.politica-privacidad',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informativaLogin/politica-privacidad').then(m => m.default || m),
    },
    
    {
        path: '/login/vendedor/preguntas-frecuentes',
        /* meta: { nombre: 'Metricas' }, */
        name: 'login.leechero.preguntas-frecuentes',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informativaLogin/preguntas-frecuentes').then(m => m.default || m),
    },
    
    {
        path: '/login/cliente/preguntas-frecuentes',
        /* meta: { nombre: 'Metricas' }, */
        name: 'login.cliente.preguntas-frecuentes',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/informativaLogin/preguntas-frecuentes').then(m => m.default || m),
    },
    {
        path: '/postulacion',
        /* meta: { nombre: 'Metricas' }, */
        name: 'login.postulacion',
        component: () => import(/* webpackChunkName: "informacion" */ '../../pages/registroPostulacion/registroPostulacion').then(m => m.default || m),
    }
]

export default [
    {
        path: '/admin/centro-distribuccion',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis',
        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis').then(m => m.default || m),
        redirect: {name: 'admin.cedis.lista'},
        children: [
            {
                path: '/admin/centro-distribuccion/lista',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.lista',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/lista').then(m => m.default || m),
            },
            {
                path: '/admin/centro-distribuccion/comparativa',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.comparativa',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/comparativa').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/admin/centro-distribuccion/crear',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.crear-cedis',
        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/crear').then(m => m.default || m),
    },
    {
        path: '/admin/centro-distribuccion/editar/:id_cedis',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.editar-cedis',
        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/editarCedis').then(m => m.default || m),
    },
    {
        path: '/admin/centro-distribuccion/ver/:id_cedis',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.ver',
        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver').then(m => m.default || m),
        redirect:{name:'admin.cedis.productos'},
        children: [
            {
                path: 'Productos',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.productos',
                // component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/productos_tienda').then(m => m.default || m),
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '~/pages/productos/admin/index').then(m => m.default || m),
            },
            {
                path: 'categorias',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.categorias',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '~/pages/cedis/ver/categorias').then(m => m.default || m),
            },
            {
                path: 'Promociones',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.promociones',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/promociones/index_promociones').then(m => m.default || m),
            },
            {
                path: 'comisiones',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.comisiones',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/comisiones').then(m => m.default || m),
            },
            {
                path: 'alianzas',
                name: 'admin.cedis.alianzas',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/alianzasLeechero').then(m => m.default || m),
                redirect: {name: 'admin.cedis.alianzas.comisiones'},
                children: [
                    {
                        path: 'comisiones',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.alianzas.comisiones',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/alianzasLeechero/comisiones').then(m => m.default || m),
                    },
                    {
                        path: 'descuento-lista',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.alianzas.descuento-lista',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/alianzasLeechero/descuento-lista').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'Estadisticas',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.estadisticas',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/almacen/estadisticas/index').then(m => m.default || m),
            },
            {
                path: 'Informacion',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.informacion',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/informacionCedis').then(m => m.default || m),
            },
            {
                path: 'combos-kits',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.combos-kits',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/combos/index').then(m => m.default || m),
                children: [
                    {
                        path: 'productos',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.combos-kits.productos',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/combos/productos').then(m => m.default || m),
                    },
                    {
                        path: 'registro',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.combos-kits.registro',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/combos/registro').then(m => m.default || m),
                    },
                    {
                        path: 'estadisticas',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.combos-kits.estadisticas',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/combos/estadisticas').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'ordenes-compra',
                meta: { nombre: 'Centros de Distribución' },
                name: 'admin.cedis.ordenes-compra',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/ordenesCompra/index').then(m => m.default || m),
                redirect: ({name: 'admin.cedis.ordenes-compra.calendario'}),
                children: [
                    {
                        path: 'calendario',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.ordenes-compra.calendario',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/ordenesCompra/calendario').then(m => m.default || m),
                    },
                    {
                        path: 'lista',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.ordenes-compra.lista',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/ordenesCompra/lista').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'cupones',
                name: 'admin.cedis.cupones',
                component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/cupones/index').then(m => m.default || m),
                redirect: {name: 'admin.cedis.cupones.clientes.lista'},
                children: [
                    {
                        path: 'clientes/lista',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.cupones.clientes.lista',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/cupones/lista').then(m => m.default || m),
                    },
                    {
                        path: 'clientes/dashboard',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.cupones.clientes.dashboard',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/cupones/dashboard').then(m => m.default || m),
                    },
                    {
                        path: 'leecheros/lista',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.cupones.leecheros.lista',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/cupones/lista').then(m => m.default || m),
                    },
                    {
                        path: 'leecheros/dashboard',
                        meta: { nombre: 'Centros de Distribución' },
                        name: 'admin.cedis.cupones.leecheros.dashboard',
                        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/cupones/dashboardLeecheros').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'cupones/ver/:id_cupon',
                name: 'admin.cedis.cupones.ver',
                meta: { nombre: 'Centros de Distribución' },
                //redirect: {name: 'admin.cupones.ver.informacion'},
                component: () => import('~/pages/cupones/ver').then(m => m.default || m),
                children: [
                    {
                        path: 'informacion/cupon',
                        name: 'admin.cedis.cupones.ver.informacion1',
                        component: () => import('~/pages/cupones/ver/informacionCupon').then(m => m.default || m),
                    },
                    {
                        path: 'informacion/bono',
                        name: 'admin.cedis.cupones.ver.informacion2',
                        component: () => import('~/pages/cupones/ver/informacionBono').then(m => m.default || m),
                    },
                    {
                        path: 'clientes-especificos',
                        name: 'admin.cedis.cupones.ver.clientes-especificos',
                        component: () => import('~/pages/cupones/ver/clientesEspecificos').then(m => m.default || m),
                    },
                ]
            }
        ]
    },
    {
        path: '/admin/centro-distribuccion/:id_cedis/historial-importaciones',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.historial-importaciones',
        component: () => import('~/pages/cedis/historialActualizacion').then(m => m.default || m),
    },
    {
        path: '/admin/centro-distribuccion/:id_cedis/importar/:id_importacion/inventario',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.importar-inventario',
        component: () => import('~/pages/cedis/importarInventario').then(m => m.default || m),
    },
    {
        path: '/admin/centro-distribuccion/:id_cedis/importar/:id_importacion/detalle',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.importar-detalle',
        component: () => import('~/pages/cedis/detalleImportacion').then(m => m.default || m),
    },
    {
        path: '/admin/centro-distribuccion/:id_cedis/ordenes-compra/:id_oc/detalle',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.ordenes-compra.detalle',
        component: () => import(/* webpackChunkName: "Centros de distribuccion" */ '../../pages/cedis/ver/ordenesCompra/detalleCompra').then(m => m.default || m),
    },
    {
        path: '/admin/centro-distribuccion/ver/:id_cedis/importar-archivo',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.importar-archivo',
        component: () => import('~/pages/cedis/ver/importarArchivo').then(m => m.default || m),
    },
    {
        path: '/admin/centro-distribuccion/:id_cedis/user/:idUser/temp-importacion-productos/',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.importar.productos.temp',
        component: () => import('~/pages/cedis/ver/importarProductos/listado').then(m => m.default || m)
    },
    {
        path: '/admin/centro-distribuccion/:id_cedis/importar',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.importar.producto',
        component: () => import('~/pages/cedis/ver/importarProductos/productoEditar').then(m => m.default || m),

    },
    {
        path: '/admin/centro-distribuccion/ver/:id_cedis/actualizar-datos',
        meta: { nombre: 'Centros de Distribución' },
        name: 'admin.cedis.actualizar-datos',
        component: () => import('~/pages/cedis/ver/actualizarDatos').then(m => m.default || m),
    }
]


<template>
    <!-- <chat2 fondo :user="id" /> -->

    <section style="height:100vh;">
        <div class="row mx-0 bg-leeche justify-content-between align-items-center px-5" style="height:80px;border-radius:0 0 0 20px;">
            <img class="ml-3" src="/img/auth/logo_blanco.svg" height="33px" style="max-width:100%;" />
            <p class="cr-pointer text-white f-600" @click="logout_admin">Cerrar sesión</p>
        </div>
        <div class="row mx-0" style="height:calc(100% - 80px);">
            <div class="col d-middle-center">
                <div class="text-center" style="max-width:350px;">
                    <img style="max-width:200px;max-height:200px;" :src="info.imagen" alt="" />
                    <div class="row mx-0 my-3 f-600 f-20 justify-center">
                        {{ info.titulo }}
                    </div>
                    <div class="row mx-0 f-18 my-3 justify-center">
                        {{ info.texto }}
                    </div>
                    <div v-if="info.btn" class="row mx-0 mt-3 justify-center">
                        <div v-if="info.btn_2" type="button" class="bg-leeche text-white p-2 br-12 px-5" name="button">
                            Finalizar
                        </div>
                        <div type="button" class="bg-leeche text-white p-2 br-12 px-5" name="button" @click="dirigir">
                            {{ info.txtBoton }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-100" style="width:28vw">
                <chat fondo :user="id_chat" :limitar-mensajes="limitar" :tipo="tipo_chat" :finalizado="false" />
            </div>
        </div>
        <!-- <modalCancelarPost ref="modalCancelarPost" /> -->
        <modal ref="modalCancelarPost" titulo="Cancelar postulación" icon="close" btn-guardar @guardar="cancelarPostulacion">
            <div class="row mx-0 justify-center text-center">
                <div class="col-10">
                    ¿Desea cancelar la postulación?
                </div>
            </div>
            <div class="row mx-0 justify-center mb-2 text-center">
                <div class="col-10 f-15">
                    Tenga en cuenta que sus datos serán eliminados, no podrá ser evaluado o el proceso se interrumpirá.
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Leecheros from '~/services/tiendas/cambio_estados'
const VUEX_RUTA = 'auth'

export default {
    name: 'LoginStop',
    layout: 'login',
    sockets:{
        activar_chat(value){
            console.log(value);
            const { tienda } = JSON.parse(value)
            if (this.id_chat == tienda.id){
                this.limitar = tienda.chat_admin == 2 ? false : true
            }
        }
    },
    data(){
        return {
            errores: {

            },
            estadosAceptados: [1, 4, 5],
            info:{
                imagen: null,
                titulo: null,
                texto: null,
                btn: false,
                btn_2: false,
                txtBoton: '',
                accion: 0
            },
            id: 0,
            ruta: 'home',
            id_chat: 0,
            limitar: true,
            tipo_chat: 1
        }
    },
    computed: {
        ...mapGetters({
            error: `${VUEX_RUTA}/obtenerError`,
        }),
        estadoTiendaEsValido(){
            return _.isUndefined(this.$usuario.tienda) || this.estadosAceptados.includes(this.$usuario.tienda.estado)
        },
        noExistenErrores(){
            return _.isEmpty(this.error)
        }
    },
    mounted(){
        this.limpiar()
        this.intentarContinuarAlInicio();
        if (this.error.usuario) this.id = this.error.usuario.id;
        this.infoVista()
    },
    methods: {
        logout_admin(){
            this.$store.dispatch('auth/intentarCerrarSesion')
            this.$router.push({name:'login.form.admin'})
        },
        intentarContinuarAlInicio(){
            if (this.estadoTiendaEsValido && this.noExistenErrores){
                let rol = this.$usuario.rol_nombre
                if(rol === 'admin' || rol === 'admin-vip'){
                    this.$router.push({name:`${rol}.cedis.lista`})
                    return
                }

                if(rol === 'nomina'){
                    this.$router.push({ name: `admin.nomina.ver.empresa`, params:{id_convenio:this.$usuario.convenio} })
                    return
                }
                this.$router.push({ name: `${rol}.home` })
            }
        },
        infoVista(){
            if(this.error.codigo === undefined){
                //console.log('error.codigo', this.error.codigo, 'this.$usuario.tienda', this.$usuario.tienda);
                if(this.$usuario.tienda !== undefined){
                    this.tipo_chat = 3
                    this.id_chat = this.$usuario.tienda.id
                    this.conEstadosDeLaTienda(this.$usuario.tienda.estado)
                }else if(this.$usuario){
                    this.tipo_chat = 1
                    this.id_chat = this.$usuario.id
                    this.info = {
                        imagen: '/img/ilustraciones/fuera_servicio.svg',
                        titulo: 'Estás fuera de servicio' ,
                        texto: 'Tu servicio está inactivo, contáctanos para mayor información.',
                        btn: false,
                    }
                }
                return
            }
            this.id_chat = this.error.usuario.id
            switch (this.error.codigo){
            case 'U002':
                this.tipo_chat = 1
                this.info = {
                    imagen: '/img/ilustraciones/grunon.svg',
                    titulo: 'Nuestro gato gruñón no te deja pasar.' ,
                    texto: 'No tienes permiso para entrar a esta sección, pero siempre puedes contactarnos a través de nuestro chat.',
                    btn: false,
                    txtBoton: ''
                }

                return
                break;
            case 'U003':
                this.tipo_chat = 1
                this.info = {
                    imagen: '/img/ilustraciones/exploradora.svg',
                    titulo: 'Detén tu espíritu explorador.' ,
                    texto: 'Por el momento los clientes solo cuentan con nuestra aplicación móvil, te recomendamos regresar a la app para seguir explorando por nuestras ofertas.',
                    btn: true,
                    txtBoton: 'Ir a la aplicación'
                }
                return
                break;
            case 'E001':
            case 'P002':
                this.info = {
                    imagen: '/img/auth/fuera_servicio.svg',
                    titulo: 'Este proyecto esta fuera de servicio' ,
                    texto: 'Tu servicio está inactivo, contáctanos para mayor información.',
                    btn: false,
                    txtBoton: ''
                }
                return
                break;
            case 'T001':
                this.tipo_chat = 3
                this.info = {
                    imagen: '/img/ilustraciones/eliminada.svg',
                    titulo: 'Tu tienda fue eliminada' ,
                    texto: 'Puedes volver a registrarte o contactarnos para cualquier inquietud.',
                    btn: false,
                    txtBoton: ''
                }
                return
                break;
            case 'T002': // condiciones de la tienda
                this.tipo_chat = 3
                this.id_chat = this.error.usuario.id_tienda
                let estadoTienda = this.error.usuario.estado
                this.limitar = this.error.usuario.chat_admin === 2 ? false: true
                this.conEstadosDeLaTienda(estadoTienda, false)
                break;
            default:
                break;
            }

        },
        conEstadosDeLaTienda(estadoTienda, verificarLimite = true){
            if(this.$usuario.tienda && verificarLimite){
                this.limitar = this.$usuario.tienda.chat.admin === 2 ? false: true
            }
            switch (estadoTienda){
            case 1:
                this.info = {
                    imagen: '/img/auth/postulacion_proceso.svg',
                    titulo: 'Tu postulacion está en proceso' ,
                    texto: 'Leeche está evaluando su postulación. Nos comunicaremos contigo cuando el proceso de evaluación esté listo.',
                    btn: true,
                    txtBoton: 'Cancelar postulación',
                    accion: 1
                }
                break;
            case 2:
                this.info = {
                    imagen: '/img/auth/postulacion_denegada.svg',
                    titulo: 'Leeche ha denegado tu postulacion' ,
                    texto: 'Lastimosamente hemos determinado que no cumples con las condiciones o criterios de selección para ser parte de la comunidad de Leeche. Nos encantaría que afinaras detalles y te postularas nuevamente.',
                    btn: true,
                    txtBoton: 'Postularte nuevamente',
                    accion: 2
                }
                break;
            case 3:
                this.info = {
                    imagen: '/img/auth/postulacion_denegada.svg',
                    titulo: 'Leeche ha denegado tu postulacion' ,
                    texto: 'Lastimosamente hemos determinado que no cumples con las condiciones o criterios de selección para ser parte de la comunidad de Leeche. Nos encantaría que afinaras detalles y te postularas nuevamente.',
                    btn: true,
                    txtBoton: 'Postularte nuevamente',
                    accion: 2
                }
                break;
            case 6:
                this.info = {
                    imagen: '/img/auth/tendero_inactivo.svg',
                    titulo: 'Estas Inactivo' ,
                    texto: 'Comunícate con nosotros para mayor información',
                    btn: false,
                    txtBoton: '',
                }

                break;
            case 7:
                this.info = {
                    imagen: '/img/auth/cancelacion_postulacion.svg',
                    titulo: 'Has cancelado tu postulación' ,
                    texto: 'Tu postulación ha sido cancelada, si quieres retomarala lo puedes hacer dando click en el botón retomar.',
                    btn: true,
                    btn_2: true,
                    txtBoton: 'Retomar',
                }
                break;

            default:
                this.info.titulo = 'Falta definir pantalla para este estado de la tienda'
                break;

            }

        },
        dirigir(){
            if(this.error.codigo ==='T002'){
                this.ruta = 'login'
            }
            if(this.info.accion===1){
                this.$refs.modalCancelarPost.toggle()
            }else if(this.info.accion===2){
                this.$router.push({ name: 'prePostulacion' })
            }else{
                this.$router.push({ name: this.ruta })

            }
        },
        async cancelarPostulacion(){
            try {
                const enviar = {
                    id: this.$usuario.tienda.id,
                    tipo: 2
                }
                const {data} = await Leecheros.cambiar_estado(enviar)
                this.notificacion('Mensaje', 'Postulación cancelada', 'success')
                this.$refs.modalCancelarPost.toggle()
                this.$router.push({ name: 'login.form.admin' })
            } catch (e){
                this.error_catch(e)
            }


        },
        limpiar(){
            this.info= {
                imagen: null,
                titulo: null,
                texto: null,
                btn: false,
                txtBoton: '',
                accion: 0
            }
            this.id_chat= 0
            this.limitar= true
            this.tipo_chat= 1
        }
    },
}
</script>

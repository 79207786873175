export default [
    {
        path: '/admin/tesoreria',
        meta: { nombre: 'Tesorería' },
        name: 'admin.tesoreria',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/tesoreria').then(m => m.default || m),
        redirect: {name: 'admin.tesoreria.creditos.dashboard'},
        children: [
            {
                path: 'tesoreria',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.tesoreria',
                component: () => import('../../pages/almacen/tesoreria/index').then(m => m.default || m),
            },
            {
                path: 'creditos',
                name: 'admin.tesoreria.creditos',
                meta: { nombre: 'Tesoreria' },
                redirect: { name: 'admin.tesoreria.creditos.dashboard' },
                component: () => import('../../pages/almacen/creditos').then(m => m.default || m),
                children: [
                    {
                        path: 'dashboard',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.creditos.dashboard',
                        component: () => import('../../pages/almacen/creditos/dashboard').then(m => m.default || m),
                    },
                    {
                        path: 'tenderos',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.creditos.tenderos',
                        component: () => import('../../pages/almacen/creditos/tenderos').then(m => m.default || m),
                    },
                    {
                        path: 'tenderos/ver/:id_tienda',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.creditos.tenderos.ver',
                        component: () => import('../../pages/almacen/creditos/tenderoVer').then(m => m.default || m),
                    },
                    {
                        path: 'cobros',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.creditos.cuotas',
                        component: () => import('../../pages/almacen/creditos/cuotas').then(m => m.default || m),
                    },
                    {
                        path: 'pedidos-cobrar',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.creditos.pedidos-cobrar',
                        component: () => import('../../pages/tesoreria/pedidoPorCobrar').then(m => m.default || m),
                    },
                    {
                        path: 'dinero-recibido',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.creditos.dinero-recibido',
                        component: () => import('../../pages/tesoreria/entregasDinero').then(m => m.default || m),
                    },

                ]
            },
            {
                path: 'comisiones',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.comisiones',
                component: () => import('../../pages/comisiones').then(m => m.default || m),
            },
            {
                path: 'alianzas',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.alianzas',
                component: () => import('../../pages/alianzasLeechero').then(m => m.default || m),
            },
            {
                path: 'entradas-dinero',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.entradas-dinero',
                component: () => import(/* webpackChunkName: "promociones" */ '../../pages/tesoreria/entregasDinero').then(m => m.default || m),
            },
            {
                path: 'liquidacion-comisiones',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.liquidacion-comisiones',
                component: () => import('../../pages/tesoreria/liquidacionComisiones/index').then(m => m.default || m),
                redirect: { name: 'admin.tesoreria.pendientes-gestionar' },
                children: [
                    {
                        path: 'pendientes-gestionar',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.pendientes-gestionar',
                        component: () => import('../../pages/tesoreria/liquidacionComisiones/pendientesGestionar').then(m => m.default || m),
                    },
                    {
                        path: 'leecheros-liquidar',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.leecheros-liquidar',
                        component: () => import('../../pages/tesoreria/liquidacionComisiones/leecherosLiquidar').then(m => m.default || m),
                    },
                    {
                        path: 'comisiones-pagadas',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.comisiones-pagadas',
                        component: () => import('../../pages/tesoreria/liquidacionComisiones/comisionesPagadas').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'flujo-dinero',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.flujo-dinero',
                component: () => import('../../pages/tesoreria/flujoDinero').then(m => m.default || m),
                redirect: { name: 'admin.tesoreria.flujo-general' },
                children: [
                    {
                        path: 'flujo-general',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.flujo-general',
                        component: () => import('../../pages/tesoreria/flujoDinero/flujoGeneral').then(m => m.default || m),
                    },
                    {
                        path: 'cajas',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.cajas',
                        component: () => import('../../pages/tesoreria/flujoDinero/cajas').then(m => m.default || m),
                    },
                    {
                        path: 'movimientos-cajas',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.movimientos-cajas',
                        component: () => import('../../pages/tesoreria/flujoDinero/movimientosCajas').then(m => m.default || m),
                    },
                    {
                        path: 'cuentas-bancarias',
                        meta: { nombre: 'Tesorería' },
                        name: 'admin.tesoreria.cuentas-bancarias',
                        component: () => import('../../pages/tesoreria/flujoDinero/cuentasBancarias').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'pasarela-pagos',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.pasarela-pagos',
                component: () => import('../../pages/tesoreria/pasarela/listadoPagos').then(m => m.default || m),
            },
            {
                path: 'deliverys',
                meta: { nombre: 'Tesorería' },
                name: 'admin.tesoreria.deliverys',
                component: () => import('../../pages/tesoreria/tabDelivery/index').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/tesoreria/creditos/cobros/ver/:id_pedido',
        meta: { nombre: 'Tesorería' },
        name: 'admin.tesoreria.creditos.cobro.ver',
        component: () => import('../../pages/tesoreria/verCobro').then(m => m.default || m),
    },
    {
        path: '/admin/tesoreria/flujo-dinero/cajas/editar',
        meta: { nombre: 'Tesorería' },
        name: 'admin.tesoreria.flujo-dinero.cajas.editar',
        component: () => import('../../pages/tesoreria/flujoDinero/editarCaja').then(m => m.default || m),
    },
    {
        path: '/admin/tesoreria/flujo-dinero/cajas/ver/:id_caja',
        meta: { nombre: 'Tesorería' },
        name: 'admin.tesoreria.flujo-dinero.cajas.ver',
        component: () => import('../../pages/tesoreria/flujoDinero/verCaja').then(m => m.default || m),
    },
    {
        path: '/admin/tesoreria/flujo-dinero/cuentas-bancarias/editar',
        meta: { nombre: 'Tesorería' },
        name: 'admin.tesoreria.flujo-dinero.cuentas-bancarias.editar',
        component: () => import('../../pages/tesoreria/flujoDinero/editarCuentasBancarias').then(m => m.default || m),
    },
    {
        path: '/admin/tesoreria/pasarela-pagos/cuentas',
        meta: { nombre: 'Tesorería' },
        name: 'admin.tesoreria.pasarela-pagos.cuentas',
        component: () => import('../../pages/tesoreria/pasarela/cuentas').then(m => m.default || m),
    }
]

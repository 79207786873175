import axios from 'axios'


const Cupones = {
    listarCarpetas(id_cedis = 0,tipoVista = 0){
        let params = {
            id_cedis,
            tipoVista
        }
        return axios(`carpetas`,{params})
    },
    crearCarpeta(form){
        return axios.post(`carpetas/crear`,form)
    },
    editarCarpeta(form){
        return axios.put(`carpetas/${form.id}/editar`,form)
    },
    cambioEstadoCarpeta(id){
        return axios.put(`carpetas/${id}/editar/estado`)
    },
    cambiarCarpeta(id_cupon,form){
        return axios.put(`cupones/${id_cupon}/change/carpeta`,form)
    },
    eliminarCarpeta(id){
        return axios.delete(`carpetas/${id}/delete`)
    },
    listarCupones(idCarpeta){
        return axios.get(`carpetas/${idCarpeta}/cupones`)
    },
    listarCuponesXCedis(idCarpeta,idCedis){
        return axios.get(`carpetas/${idCarpeta}/cedis/${idCedis}/cupones`)
    },
    crearCupon(form){
        return axios.post(`cupones/crear-basico`,form)
    },
    editarCupon(form,id_cupon){
        return axios.put(`cupones/${id_cupon}/editar`,form)
    },
    cambiarEstado(id_cupon){
        return axios.put(`cupones/${id_cupon}/estado`)
    },
    getDatosBasicos(id_cupon){
        return axios.get(`cupones/${id_cupon}/basico`)
    },
    listarPaises(){
        return axios.get(`cupones/listado-paises`)
    },
    listarCedis(idPais){
        return axios.get(`get-cedis/pais/${idPais}/lista`)
    },
    getCuponesCedis(idCupon){
        return axios.get(`cupones/${idCupon}/cedis`)
    },
    agregarCedis(form){
        return axios.post(`cupones/${form.id_cupon}/add/cedis`,form)
    },
    removerCedis(id){
        return axios.delete(`cupones/cedis/${id}/delete`)
    },
    cambioTipoDescuento(idCupon,value){
        return axios.put(`cupones/${idCupon}/change/tipo/${value}/descuento`)
    },
    cambioDescuento(idCupon,form){
        return axios.put(`cupones/${idCupon}/change/descuento`,form)
    },
    cambiarSinLimite(idCupon,form){
        return axios.put(`cupones/${idCupon}/change/sin-limite`,form)
    },
    cambiarLimite(idCupon,form){
        return axios.put(`cupones/${idCupon}/change/limite`,form)
    },
    cambiarCondiciones(idCupon,form){
        return axios.put(`cupones/${idCupon}/change/condiciones`,form)
    },
    cambiarFecha(idCupon,form){
        return axios.put(`cupones/${idCupon}/change/fechas`,form)
    },
    getCategorias(){
        return axios.get(`categorias/get-listado`)
    },
    getCategoriasCupon(idCupon){
        return axios.get(`cupones/${idCupon}/categorias`)
    },
    agregarCategoria(idCupon,form){
        return axios.post(`cupones/${idCupon}/add/categoria`,form)
    },
    eliminarCategoria(id){
        return axios.delete(`cupones/categoria/${id}/delete`)
    },
    getDatosBasicosFututo(idPadre){
        return axios.get(`cupones/futuro/${idPadre}/basico`)
    },
    crearCuponFuturo(form){
        return axios.post(`cupones/futuro/crear`,form)
    },
    listarClientes(idCupon){
        return axios.get(`cupones/${idCupon}/clientes`)
    },
    listarClientesNoAgregados(idCupon,params){
        return axios.get(`cupones/${idCupon}/clientes/no-agregados`,{params})
    },
    agregarClientes(idCupon,form){
        return axios.post(`cupones/${idCupon}/add/clientes`,form)
    },
    eliminarCliente(form){
        return axios.put(`cupones/${form.id_cupon}/update/cliente`,form)
    },
    eliminarClienteMasivo(form){
        return axios.put(`cupones/${form.id_cupon}/update/masivo/clientes`,form)
    },
    cambiarLimiteUsoMasivo(form){
        return axios.put(`cupones/${form.id_cupon}/update/masivo/limite-uso`,form)
    },
    cambiarVigenciaMasivo(id_cupon,form){
        return axios.put(`cupones/${id_cupon}/update/masivo/vigencia`,form)
    },
    getDetalleCupon(id_cupon){
        return axios.get(`cupones/${id_cupon}/detalle`)
    },
    calcularClientes(id_cupon){
        return axios.get(`cupones/${id_cupon}/calcular/clientes`)
    },
    duplicarCupon(id_cupon, form){
        return axios.post(`cupones/${id_cupon}/duplicar`,form)
    },
    getLecherosCupon(id_cupon){
        return axios.get(`cupones/${id_cupon}/lecheros`)
    },
    agregarLechero(id_cupon, form){
        return axios.post(`cupones/${id_cupon}/add/lechero`,form)
    },
    eliminarLechero(id_cupon, id_tienda){
        return axios.delete(`cupones/${id_cupon}/lechero/${id_tienda}`)
    },


}

export default Cupones

<template>
    <section>
        <div class="navbar bg-white border-bottom py-0 pl-0">
            <div class="h-100 bg-general space-bell d-middle-center" style="width:60px;">
                <img :src="$config.logo_cuadro" width="45" height="45" alt="" />
            </div>
            <div class="col-auto logo-column">
                <img :src="$config.logo" height="30px" style="max-width:180px;height: 40px;" />
            </div>
            <div class="col-auto text-general mt-1 f-600">
                {{ nombre }}
            </div>
            <div v-if="rol === 'admin' || rol === 'admin-vip'" class="col d-middle justify-content-end px-0">
                <i v-if="habilitarCedis" class="icon-cedis f-18 mr-2" />
                <el-popover
                v-if="habilitarCedis"
                placement="bottom"
                width="300"
                trigger="hover"
                popper-class="p-2 br-8"
                class="border br-4 options-navbar px-0"
                >
                    <!-- Content Popover -->
                    <p class="text-general f-14 f-600 mb-1">CEDIS Seleccionados</p>
                    <div class="custom-scroll overflow-auto" style="max-height:180px;">
                        <template v-if="cedisNavbar.length">
                            <p v-for="(cedi, c) in cedisNavbar" :key="c" class="text-general f-14 mb-1 pl-3">{{ cedi.nombre }}</p>
                        </template>
                        <template v-else>
                            <p v-for="(cedi, c) in cedisTodos" :key="c" class="text-general f-14 mb-1 pl-3">{{ cedi.nombre }}</p>
                        </template>
                    </div>
                    <!-- Content Popover -->
                    <div v-if="habilitarCedis" slot="reference" class="d-middle px-2 w-100" style="height:32px;">
                        <div v-if="cedisNavbar.length" class="col-xl-8 col-lg-9 col-md-8 col-sm-7 col-7 pl-0 pr-2 tres-puntos">
                            <span v-for="(cedi, c) in cedisNavbar" :key="c" class="text-general f-14">
                                {{ cedi.nombre }},
                            </span>
                        </div>
                        <div v-else class="col tres-puntos pl-0">
                            <span class="text-general f-14">Todos</span>
                        </div>
                        <div v-if="cedisNavbar.length" class="bg-light-f5 d-middle ml-auto justify-center border f-12 br-20 px-1 wm-38px">
                            + {{ cedisNavbar.length }}
                        </div>
                        <i class="icon-plus f-17 cr-pointer ml-1" @click="seleccionarCedisNavbar" />
                    </div>
                </el-popover>
                <i class="icon-money f-15 ml-3" />
                <div class="col-1 px-0 mx-2 mr-4 options-navbar">
                    <el-select v-model="id_moneda" class="w-100 select-32" :disabled="!cargandoTerminado" size="mini">
                        <el-option
                        v-for="item in monedas"
                        :key="item.id"
                        :label="`${item.sigla}`"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <el-tooltip
                placement="bottom"
                effect="light"
                content="Calificación de satisfacción"
                >
                    <div class="position-relative options-navbar bg-whitesmoke border br-5" style="width:110px;height:20px;">
                        <img class="rounded-circle icon-barra" width="25" height="25" src="/img/icons/emotes/MeEncanta.svg" alt="" />
                        <div class="bg-general h-100 br-5" :style="`width:${satisfaccion}%`" />
                    </div>
                </el-tooltip>
                <span class="text-general mx-2">{{ satisfaccion }}%</span>
                <i class="icon-bell-outline f-22  mx-2 cr-pointer" @click="notificaciones" />
                <el-popover
                placement="bottom"
                popper-class="p-2 br-8"
                trigger="click"
                >
                    <p class="br-5 px-2 cr-pointer" @click="logout_admin">Cerrar Sesión</p>
                    <div slot="reference" class="rounded-circle d-middle-center cr-pointer bg-general p-1" style="width:32px;height:32px;outline:none;">
                        <img width="100%" height="100%" :src="$config.logo_cuadro" class="cr-pointer rounded-circle" />
                    </div>
                </el-popover>
            </div>
            <div v-if="rol === 'nomina'" class="col d-middle justify-content-end pr-0">
                <el-popover
                placement="bottom"
                popper-class="p-2 br-8"
                trigger="click"
                >
                    <p class="br-5 px-2 cr-pointer" @click="logout_admin">Cerrar Sesión</p>
                    <div slot="reference" class="rounded-circle d-middle-center cr-pointer bg-general p-1" style="width:32px;height:32px;outline:none;">
                        <img width="100%" height="100%" :src="$config.logo_cuadro" class="cr-pointer rounded-circle" />
                    </div>
                </el-popover>
            </div>
            <div v-if="rol === 'tendero'" class="col px-0 d-middle justify-content-end">
                <div v-if="$route.name != 'tendero.carrito.checkout'" class="d-flex mr-2 cr-pointer" @click="anadirDirecciones()">
                    <i class="icon-map-marker-outline text-general f-22" />
                    <div class="border br-6 px-2 d-middle-center cr-pointer tres-puntos direction-box" style="height:32px;width:343px;">
                        <span class="f-14 text-general tres-puntos">{{ _.get(principal, 'direccion', 'No registra') }}</span>
                        <i class="icon-angle-down text-gris ml-2" />
                    </div>
                </div>
                <el-tooltip class="item" effect="light" content="Ventas de hoy" placement="bottom">
                    <div class="br-20 bg-general3 px-2 d-middle text-white mx-2">
                        <i class="icon-cash-multiple f-18" />
                        <p>{{ separadorNumero(tienda.total_pedidos,$tienda.decimales) }}</p>
                    </div>
                </el-tooltip>
                <el-tooltip class="item" effect="light" placement="bottom">
                    <div slot="content" class="text-center" style="width:130px;">
                        Índice de cancelación de los clientes
                    </div>
                    <div class="br-20 bg-general2 px-2 d-middle text-white mx-2">
                        <i class="icon-account-remove-outline f-18" />
                        <p>{{ tienda.cancelacion_cliente }}%</p>
                    </div>
                </el-tooltip>
                <el-tooltip class="item" effect="light" placement="bottom">
                    <div slot="content" class="text-center" style="width:130px;">
                        Índice de cancelación de la tienda
                    </div>
                    <div class="br-20 bg-general2 px-2 d-middle text-white mx-2">
                        <i class="icon-lock f-18" />
                        <p>{{ tienda.cancelacion_tienda }}%</p>
                    </div>
                </el-tooltip>
                <div class="cr-pointer" @click="open_calificaciones">
                    <span class="ml-3">{{ redondearNumero(tienda.calificacion,1) }}</span>
                    <i class="icon-star text-yellow mx-2" />
                </div>
                <i class="icon-bell-outline f-22 mx-2 cr-pointer" @click="notificaciones" />
                <div v-if="tienda.clasificacion != null" slot="reference" class="rounded-circle d-middle-center cr-pointer bg-general p-1 mx-2" style="width:32px;height:32px;outline:none;">
                    <el-tooltip :raw-content="true" placement="bottom" effect="light">
                        <template #content>
                            <div style="width:130px;">
                                <span class="d-middle-center text-black font-weight-bold">Usuario {{ tienda.clasificacion.nombre }}</span> <br /> <span class="text-gris2">Te descontamos de {{ tienda.clasificacion.min_descuento * 10 }}% a {{ tienda.clasificacion.max_descuento * 10 }}% por compras superiores a {{ separadorNumero(tienda.clasificacion.compra_desde) }} </span>
                            </div>
                        </template>
                        <img width="100%" height="100%" :src="tienda.clasificacion.img_clasificacion" class="cr-pointer rounded-circle" />
                    </el-tooltip>
                </div>
                <el-popover
                placement="bottom"
                trigger="click"
                popper-class="p-2 br-8"
                width="140"
                >
                    <div>
                        <p class="br-5 px-2 cr-pointer" @click="ir_perfil">Ver Perfil</p>
                        <p class="br-5 px-2 cr-pointer" @click="logout_tendero">Cerrar Sesión</p>
                    </div>
                    <div slot="reference" class="rounded-circle d-middle-center cr-pointer bg-general p-1" style="width:32px;height:32px;outline:none;">
                        <img width="100%" height="100%" :src="tienda.logo_mini" class="cr-pointer rounded-circle" />
                    </div>
                </el-popover>
            </div>
            <!-- <i class="icon-menu f-20 text-general" @click="openLateralMenu" /> -->
        </div>
        <modalNotificaciones ref="modalNotificaciones" />
        <modalCalificacionesGenerales ref="CalificacionesGenerales" />
        <modalConfirmar
        ref="modalCerrarTienda"
        titulo="Cerrar Leechero"
        mensaje="¿Desea cerrar la tienda? Se mantendrá abiertos los pedidos activos, no recibirá pedidos en el momento y podrá abrirla cuando lo desee."
        icon="cerrado"
        @guardar="abrir_cerrar_tienda(true)"
        />
        <modal ref="modalDirecciones" titulo="Direcciones" icon="ubicación" no-aceptar no-cancelar adicional="Añadir nueva dirección" @adicional="anadirNuevaDireccion">
            <div class="row mx-0 justify-center">
                <div v-for="(direccion, i) in direcciones" :key="i" class="col-10 d-flex mb-2">
                    <i :class="`icon-map-marker-outline ${direccion.principal ? 'text-success' : 'text-gris cr-pointer'}  f-20`" @click="actualizarPrincipal(direccion)" />
                    <div class="col pl-2">
                        <p :class="`nombre ${direccion.principal ? 'text-general' : 'text-general2'}  f-15 f-600 `">{{ direccion.direccion }} </p>
                        <p :class="`${direccion.principal ? 'text-general' : 'text-general2'}  f-14 mt-1`">{{ direccion.nombre }}</p>
                    </div>
                    <template v-if="direccion.editando">
                        <i class="icon-pencil-outline text-general f-20 cr-pointer" @click="editarDireccion(direccion)" />
                        <i class="icon-trash-empty text-general f-20 cr-pointer" @click="modalEliminarDireccion(direccion)" />
                    </template>
                    <i v-else class="icon-dots-vertical text-general f-20 cr-pointer" @click="direccion.editando = !direccion.editando;" />
                </div>
            </div>
        </modal>
        <modal-eliminar
        ref="eliminarDireccion"
        titulo="Eliminar direccion"
        mensaje="¿Desea eliminar la dirección?"
        @eliminar="eliminarDireccion()"
        />
        <modal-crear-direccion ref="modalCrearDireccion" @volver="volverModal" @actualizar="fetchDirecciones()" />
        <modal-cedis-navbar ref="modalCedisNavbar" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import direcciones from '~/services/tiendas/direcciones'
const rutas_exentas = [
    new RegExp('admin.cedis'),
    new RegExp('admin.conf'),
    new RegExp('admin.promociones'),
    new RegExp('admin.equipos'),
    new RegExp('admin.clientes.ver'),
    // new RegExp('admin.estadisticas.movimientos'),
    new RegExp('admin.proveedores.ver'),
    new RegExp('admin.publicidad'),
]
export default {
    components: {
        modalCalificacionesGenerales: () => import('../pages/calificaciones/partials/modalCalificacionesGenerales'),
        modalCrearDireccion: () => import('../pages/auth/partials/modalCrearDirecciones')
    },
    data(){
        return{
            // direccion: {}
            id: 0,
        }
    },
    computed:{
        ...mapGetters({
            tienda:'tiendas/navbar/tienda',
            cedis:'cedis/cedis',
            cedisNavbar:'cedis/cedisNavbar',
            id_cedis:'cedis/id_cedis',
            id_cedis_navbar:'cedis/id_cedis_navbar',
            cargandoTerminado:'cedis/cargandoTerminado',
            monedas:'cedis/monedas',
            satisfaccion:'tiendas/navbar/satisfaccion',
            direcciones: 'tiendas/direcciones/direcciones',
            principal: 'tiendas/direcciones/principal'
        }),
        // id_cedis: {
        //     get(){
        //         return this.$store.state.cedis.id_cedis
        //     },
        //     set(value){
        //         this.$store.commit('cedis/set_id_cedis', value)
        //     }
        // },
        id_moneda: {
            get(){
                return this.$store.state.cedis.id_moneda
            },
            set(value){
                this.$store.commit('cedis/set_id_moneda', value)
                this.$store.commit('cedis/set_moneda', value)
            }
        },
        cedisTodos(){
            return this.cedis.filter(el => el.id != 0)
        },
        name(){
            return this.$route.name
        },
        nombre(){
            return this.$route.meta.nombre
        },
        rol(){
            return this.$usuario.rol_nombre
        },
        estado(){
            if(this.tienda.estado !== 5){
                return 'Pendiente de Publicación'
            }
            if(this.tienda.abierta === 0){
                if(this.tienda.pedidos){
                    return 'Cerrada con Pedidos'
                }else{
                    return 'Leechero Cerrada'
                }
            }
            return 'Leechero Abierta'
        },
        color(){
            if(this.tienda.estado !== 5){
                return 'bg-grayopaco'
            }
            if(this.tienda.abierta === 0){
                return 'bg-gr-red'
            }
            return 'bg-gr-general'
        },
        habilitarCedis(){
            return !rutas_exentas.some(el => el.test(this.$route.name))
        }
    },
    watch:{
        name(val){
            if(this.rol === 'tendero'){
                this.informacion_general()
            }
        },
    },
    mounted(){
        this.info_navbar()

        if(_.isNil(this.$tienda.id) || this.$tienda.estado == 5) return
        this.sockets.subscribe(`cambio-tienda-estado-${this.$tienda.id}`, this.handleSocketData)
    },
    methods: {
        async handleSocketData(data){
            let { data:estado } = JSON.parse(data);
            if (estado == 3){
                this.notificacion('', 'Tu postulación ha sido cancelada', 'error')
                await this.$store.dispatch('auth/intentarCerrarSesion')
                return this.$router.push({ name: 'login.form.admin' })
            } else {
                this.notificacion('', 'Tu postulación ha sido aprobada', 'success')
                await this.$store.dispatch('auth/consultarUsuario')
            }
        },
        async anadirDirecciones(){
            await this.fetchDirecciones()
            this.$refs.modalDirecciones.toggle();
        },
        seleccionarCedisNavbar(){
            let cedisValidos = this.cedis.reduce((acc, el) => {
                if(el.id != 0){
                    el.checked = this.cedisNavbar.some(ele => ele.id == el.id)
                    acc.push(el)
                }
                return acc
            }, [])
            this.$refs.modalCedisNavbar.toggle(cedisValidos);
        },
        editarDireccion(direccion){
            // this.direccion = direccion;
            this.$refs.modalDirecciones.toggle();
            console.log({direccion});
            this.$refs.modalCrearDireccion.editar(direccion);
        },
        modalEliminarDireccion({id}){
            if(this.direcciones.length == 1) return this.notificacion('Advertencia', 'No se puede eliminar esta dirección, es la única existente', 'warning')

            this.id = id
            this.$refs.modalDirecciones.toggle();
            this.$refs.eliminarDireccion.toggle();

        },

        anadirNuevaDireccion(){
            this.$refs.modalDirecciones.toggle();
            this.$refs.modalCrearDireccion.toggle();
        },
        volverModal(){
            this.$refs.modalCrearDireccion.toggle();
            this.$refs.modalDirecciones.toggle();
        },
        info_navbar(){
            if(this.rol === 'tendero' || this.rol === 'tendero-vip'){
                this.informacion_general()
                this.fetchDirecciones()
            }
            else if(this.rol === 'admin' || this.rol === 'admin-vip'){
                this.info_admin()
            }
        },
        async informacion_general(){
            await this.$store.dispatch('tiendas/navbar/navbar_tendero')
        },
        async fetchDirecciones(){
            await this.$store.dispatch('tiendas/direcciones/fetchDirecciones')
        },
        async info_admin(){
            await this.$store.dispatch('tiendas/navbar/navbar_admin')
        },
        modal_cerrar_tienda(){
            if(this.tienda.estado === 5 && this.tienda.abierta === 1){
                this.$refs.modalCerrarTienda.toggle()
                return
            }
            if(this.tienda.abierta === 0 && this.tienda.estado === 5){
                this.abrir_cerrar_tienda()
            }
        },
        async abrir_cerrar_tienda(modal = false){
            await this.$store.dispatch('tiendas/navbar/abrir_tienda',this.tienda.id)
            if(modal){
                this.$refs.modalCerrarTienda.toggle()
            }
            this.informacion_general()
        },
        async logout_tendero(){
            if(this.tienda.abierta === 1){
                await this.$store.dispatch('tiendas/navbar/abrir_tienda',this.tienda.id)
            }
            this.$store.dispatch('auth/intentarCerrarSesion')
            this.$router.push({name:'login.form.admin'})
        },
        logout_admin(){
            this.$store.dispatch('auth/intentarCerrarSesion')
            this.$router.push({name:'login.form.admin'})
        },
        ir_perfil(){
            if(this.name === `${this.rol}.conf.datosTienda`) return
            this.$router.push({name:`${this.rol}.conf.datosTienda`})
        },
        ir_stock_bajo(){
            if(this.name === `${this.rol}.productos`) return
            this.$router.push({name:`${this.rol}.productos`,params:{stock:true}})
        },
        open_calificaciones(){
            this.$refs.CalificacionesGenerales.get_resumen_calificacion2(this.$tienda.id)
        },
        notificaciones(){
            this.$refs.modalNotificaciones.toggle()
        },
        openLateralMenu(){
            this.$emit('click')
        },
        async actualizarPrincipal(direccion){
            try {
                if(direccion.principal) return
                const { data } = await direcciones.direccionPrincipal(direccion.id)
                if(data.exito) await this.fetchDirecciones()

            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarDireccion(){
            try {
                const { data } = await direcciones.eliminar(this.id)
                if(data.message == 'Exito'){
                    this.notificacion('', 'Dirección eliminada correctamente', 'success')
                    await this.fetchDirecciones()
                    this.$refs.eliminarDireccion.toggle();
                    this.$refs.modalDirecciones.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="css" scoped>
.navbar{
    position: fixed;
    width: 100vw;
    height: 60px;
    left: 0px;
    top: 0px;
    z-index: 0;
    box-shadow: 1px 0px 2px #00000029;
}

.space-bell{
    background-color: var(--color-general);
}

.avatar-role{
    width: 32px;
    height: 32px;
    background-color: #EDF0FA;
    box-shadow: 0px 3px 6px #00000029;
}
.item-logout:hover{
    background-color: #f5f5f5;
    color: #000000;
}
.text-yellow{
    color: #FF9D32;
}
.icon-barra{
    position: absolute;
    left: -20px;
    top: -4px;
}

.options-navbar{
    width:240px;
}

@media (max-width : 1366px){
    .options-navbar{
        width:210px;
    }
    .direction-box{
        width: 300px !important;
    }
}

@media (max-width : 1056px){
    .direction-box{
        width: 251px !important;
    }
    .logo-column{
        display: none;
    }
}

@media (max-width : 992px){
    .options-navbar{
        width:200px;
    }
    .direction-box{
        width: 180px !important;
    }
}


@media (max-width : 979px){
    .navbar{
        width: 100vw;
        left: 0px;
    }
    .options-navbar{
        display: none;
    }
}
</style>

export default [
    {
        path: '/publicidad',
        meta: { nombre: 'Promociones' },
        name: 'admin.publicidad',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/publicidad').then(m => m.default || m),
        redirect: {name: 'admin.publicidad.imagenes-pop-up'},
        children: [
            {
                path: 'imagenes-pop-up',
                meta: { nombre: 'Promociones' },
                name: 'admin.publicidad.imagenes-pop-up',
                component: () => import(/* webpackChunkName: "promociones" */ '../../pages/publicidad/imagenesPopUp').then(m => m.default || m),
                redirect: {name: 'admin.publicidad.imagenes-pop-up.clientes'},
                children: [
                    {
                        path: 'clientes',
                        name: 'admin.publicidad.imagenes-pop-up.clientes',
                        meta: { nombre: 'Publicidad' },
                        component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/publicidad/clientes').then(m => m.default || m),
                    },
                    {
                        path: 'leecheros',
                        name: 'admin.publicidad.imagenes-pop-up.leecheros',
                        meta: { nombre: 'Publicidad' },
                        component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/publicidad/leecheros').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'codigo-leechero',
                name: 'admin.publicidad.codLeechero',
                meta: { nombre: 'Publicidad' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/codLeechero').then(m => m.default || m),
            },
            {
                path: 'aspecto-correos',
                name: 'admin.publicidad.aspectCorreos',
                meta: { nombre: 'Publicidad' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/aspectCorreos').then(m => m.default || m),
            },
            {
                path: 'catalogo-productos',
                name: 'admin.publicidad.catProductos',
                meta: { nombre: 'Publicidad' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/configurar/admin/catProductos').then(m => m.default || m),
            },
            {
                path: 'mensajes-notificacion',
                name: 'admin.publicidad.mensajes-notificacion',
                meta: { nombre: 'Publicidad' },
                component: () => import(/* webpackChunkName: "configurarAdmin" */ '../../pages/publicidad/mensajesNotificacion/index').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/publicidad/ver/:tipo/:id_cedis',
        meta: { nombre: 'Promociones' },
        name: 'admin.publicidad.ver',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/publicidad/ver').then(m => m.default || m),
    },
    {
        path: '/publicidad/mensaje-notificacion/campana/:id_campana',
        meta: { nombre: 'Promociones' },
        name: 'admin.publicidad.mensaje-notificacion.campana',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/publicidad/mensajesNotificacion/ver').then(m => m.default || m),
        
    },
    {
        path: '/publicidad/mensaje-notificacion/campana/:id_campana/lanzamiento/:id_lanzamiento',
        meta: { nombre: 'Promociones' },
        name: 'admin.publicidad.mensaje-notificacion.lanzamiento',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/publicidad/mensajesNotificacion/lanzamiento').then(m => m.default || m),
    },
    {
        path: '/publicidad/mensaje-notificacion/resultado/:id_campana',
        meta: { nombre: 'Promociones' },
        name: 'admin.publicidad.mensaje-notificacion.resultado',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/publicidad/mensajesNotificacion/verResultado').then(m => m.default || m),
    },
    {
        path: '/publicidad/mensaje-notificacion/campana/:id_campana/lanzamiento/:id_lanzamiento/buscar-usuarios',
        meta: { nombre: 'Promociones' },
        name: 'admin.publicidad.mensaje-notificacion.buscar-usuarios',
        component: () => import(/* webpackChunkName: "promociones" */ '../../pages/publicidad/mensajesNotificacion/buscarUsuarios').then(m => m.default || m),
    }
]
